import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { SRLWrapper } from "simple-react-lightbox";
import Play from "../assets/svg/play.svg";

const WorkStyles = styled.div`
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;

    svg {
      filter: drop-shadow(2px 2px 18px var(--black));
    }
  }

  .page-content {
    padding: 5rem 3rem;

    .sub-title {
      font-family: Helvetica, Arial, sans-serif;
      margin-bottom: 2.5rem;
    }

    h1 {
      margin-bottom: 5rem;
    }

    .site-link {
      color: var(--blue);
      text-decoration: none;
      margin-bottom: 3rem;
      display: block;
    }

    p {
      line-height: 2.1rem;
      color: var(--p-grey);
    }

    .staff {
      margin: 5rem 0;
      font-weight: 700;

      &:last-child {
        margin-bottom: 0;
      }

      .position {
        font-family: "Roboto Condensed", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1.4rem;
        color: var(--blue);
      }
    }
  }

  .gallery {
    padding: 3rem 0;

    .swiper-slide {
      width: 75% !important;

      a {
        display: block;
        position: relative;

        .gatsby-image-wrapper {
          padding-bottom: 100%;
        }
      }
    }
  }

  .testimonial {
    position: relative;
    padding: 12rem 3rem 7rem;
    color: var(--p-grey);

    blockquote {
      z-index: 3;
      position: absolute;
      top: 3px;
      left: 18px;
      font-size: 180px;
      font-weight: 700;
      color: var(--text);
      margin: 0;
      padding: 0;
    }

    .author {
      margin-top: 4rem;
    }
  }

  @media screen and (min-width: 981px) {
    .player-wrapper {
      .react-player {
        svg {
          width: 85px;
          height: 104px;
        }
      }
    }

    .page-content {
      padding: 15rem 3rem;

      .row {
        .col {
          flex: 0 0 calc(20% - 2rem);

          .site-link {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            flex: 0 0 calc(45% - 2rem);
            margin: 0 10%;

            p {
              margin-bottom: 4rem;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          &:last-child {
            flex: 0 0 calc(15% - 2rem);
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .staff {
              font-size: 1.6rem;
              margin: 0 0 5rem;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .gallery {
      padding: 0;

      .swiper-container {
        overflow: visible;
      }

      .swiper-wrapper {
        width: 100% !important;
        flex-wrap: wrap;
        transform: none !important;
      }

      .swiper-slide {
        width: 20% !important;
        flex: 0 0 20%;
        padding: 1px;
        box-sizing: border-box;

        a {
          transition: opacity 0.3s ease;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    .testimonial {
      position: relative;
      padding: 15rem 3rem 15rem 18rem;
      font-size: 2rem;
      line-height: 2.4rem;

      blockquote {
        top: 230px;
        left: 30px;
      }

      p {
        font-size: 2rem;
        line-height: 2.4rem;
      }

      .author {
        margin-top: 4rem;
      }
    }
  }
`;

const WorkPage = ({ data }) => {
  return (
    <WorkStyles>
      <div className="player-wrapper">
        <ReactPlayer
          url={data.acfWork.video}
          className="react-player"
          light
          playing
          playIcon={<Play />}
          controls
          width="100%"
          height="100%"
        />
      </div>
      <div className="container">
        <div className="page-content content">
          <div className="row">
            <div className="col">
              <div className="sub-title">{data.acfWork.subTitle}</div>
              <h1>{data.title}</h1>
              <a href={data.acfWork.url} className="site-link">
                {data.acfWork.url}
              </a>
            </div>
            <div className="col">
              <div
                dangerouslySetInnerHTML={{
                  __html: data.content,
                }}
              />
            </div>
            <div className="col">
              {data.acfWork.staff.map((item, index) => (
                <div className="staff" key={index}>
                  <div className="position">{item.position}</div>
                  {item.name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="gallery">
          <SRLWrapper>
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={2}
              breakpoints={{
                981: {
                  spaceBetween: 0,
                },
              }}
            >
              {data.acfWork.gallery.map((item, index) => (
                <SwiperSlide>
                  <a href={item.sourceUrl}>
                    <Img
                      loading="eager"
                      fluid={item.imageFile.childImageSharp.fluid}
                      alt={item.altText}
                    />
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          </SRLWrapper>
        </div>
        <div className="testimonial">
          <blockquote>“</blockquote>
          <div
            dangerouslySetInnerHTML={{
              __html: data.acfWork.testimonial.content,
            }}
          />
          <div className="author">
            <strong>— {data.acfWork.testimonial.author}</strong>, {data.acfWork.testimonial.title}
          </div>
        </div>
      </div>
    </WorkStyles>
  );
};

export default WorkPage;
