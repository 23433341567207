import React from "react";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import Layout from "../components/layout";
import WorkItem from "../components/work-item";

const WorkWrap = ({ data }) => {
  const slug = data.wpgraphql.work.slug;

  return (
    <Layout page={slug} key={data.wpgraphql.work.id}>
      <Seo post={data.wpgraphql.work} />
      <WorkItem data={data.wpgraphql.work} />
    </Layout>
  );
};

export default WorkWrap;

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      work(id: $databaseId, idType: DATABASE_ID) {
        id
        acfWork {
          staff {
            name
            position
          }
          subTitle
          testimonial {
            author
            content
            title
          }
          video
          url
          gallery {
            imageFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
              }
            }
            srcSet
            sizes
            sourceUrl
          }
        }
        featuredImage {
          node {
            imageFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
              }
            }
            sizes
            sourceUrl
            srcSet
          }
        }
        title
        slug
        content
        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`;
